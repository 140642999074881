<template>
  <div class="md-layout text-center" id="login-tile">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <loader :loader="loader" />
      <login-card header-color="primary">
        <img slot="title" :src="MyMdesignLogo" alt="MyMdesignLogo" class="img-title" />
        <div slot="inputs">
          <ValidationObserver v-slot="{ handleSubmit, invalid }">
            <form @submit.prevent="handleSubmit(submit)">
              <ValidationProvider name="user" rules="required|email" v-slot="{ passed, failed }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t('email') }}</label>
                  <md-input v-model="user" type="text" id="user" v-on:click="changeType()"> </md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
              <ValidationProvider
                name="pass"
                :rules="{
                  required: true,
                  regex: regExValid,
                }"
                v-slot="{ passed, failed }"
              >
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label>{{ $t('password') }}</label>
                  <md-input v-model="password" type="password" v-on:keyup.enter="getContact()" id="password"></md-input>
                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </form>
            <div class="remaining-attempts">
              <span v-if="wrongPw">{{ $t('remainingAttempts') + userStore.lockout.Attempts }}</span>
            </div>
            <div v-if="showResendMail" class="md-layout-item md-size-100 just-center resend-mail-content">
              <div class="resend-mail">
                <span
                  >{{ $t('resendMail') }}<br />
                  {{ $t('clickHere') }}
                  <a @click="resendConfirmMail">{{ $t('resendMailLink') }}</a>
                </span>
              </div>
            </div>

            <div class="forgot-password">
              <a @click="$router.push({ name: 'Forgot Password' })">{{ $t('forgotPassword') }}</a>
            </div>
            <div class="cta-style">
              <md-button
                slot="footer"
                class="md-simple md-round"
                id="registration-button"
                @click.native="$router.push({ name: 'Registration' })"
                ><md-icon>how_to_reg</md-icon>
                {{ $t('registration') }}
              </md-button>
              <md-button
                slot="footer"
                class="md-primary md-round"
                v-on:click="getContact()"
                :disabled="invalid"
                id="Submit"
                ><md-icon>login</md-icon>
                {{ $t('letsgo') }}
              </md-button>
            </div>
          </ValidationObserver>
        </div>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components'
import { SlideYDownTransition } from 'vue2-transitions'
import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { useUserStore } from '../../stores/user'
import { useCompanyStore } from '../../stores/company'
import { useConfigurationProductLevelStore } from '../../stores/configurationProductLevel'
import { useManagementStore } from '../../stores/management'
import Swal from 'sweetalert2'
import { sendEmailConfimation, sendEmailConfimationAnonym } from '../../libs/emailSender'
import { httpPost, ApiNames } from '@/libs/httpHelper'

extend('email', email)
extend('required', required)

export default {
  components: {
    LoginCard,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'robots',
          content: this.$route.meta.robots,
        }, {
          name: 'description',
          content: this.$t('metaDescrLogin'),
        }, {
          name: 'keywords',
          content: this.$route.meta.keywords
        }
      ],
    }
  },
  setup() {
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const managementStore = useManagementStore()

    return { userStore, companyStore, configurationProductLevelStore, managementStore }
  },
  beforeMount() {
    this.$changePageTitle('MyMdesign - Login')
  },
  mounted() {
    this.user = this.userStore.userData.Email
    const userElement = document.getElementById('user')
    if (userElement) {
      const clickEvent = new MouseEvent('focus', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      userElement.dispatchEvent(clickEvent)
    }
    const passwordElement = document.getElementById('password')
    if (passwordElement) {
      const clickEvent = new MouseEvent('focus', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      passwordElement.dispatchEvent(clickEvent)
    }
  },
  methods: {
    async getContact() {
      this.loader = true
      this.wrongPw = false
      this.userStore.createDefaultUserData()
      this.companyStore.resetCompanyStore()
      this.configurationProductLevelStore.resetConfigurationProductLevelStore()
      // this.managementStore.resetManagementStore()
      const loginUser = await this.userStore.loginUser(this.user, this.password)

      this.loader = false

      if (this.userStore.valid) {
        this.loader = true
        var res = await this.managementStore.getPermissionsByRoleID(this.userStore.roleID, this.userStore.token)
        this.loader = false
        if (res.status === 200) {
          res.data.forEach((el) => {
            this.userStore.permissions.push(el.PermissionID)
          })
        }
      }

      if (this.userStore.valid && this.userStore.tfa.Enabled) {
        this.$router.push({ name: 'Enter Tfa Code' })
        return
      }

      if (this.userStore.valid && this.userStore.role.includes('Student')) {
        this.$router.push({ name: 'My Products' })
      } else if (this.userStore.valid && this.userStore.role.includes('Customer')) {
        this.$router.push({ name: 'Company Products' })
      } else if (this.userStore.valid && this.userStore.role.includes('Admin')) {
        this.$router.push({ name: 'Companies' })
      } else if (this.userStore.valid && this.userStore.role.includes('Licenser')) {
        this.$router.push({ name: 'User' })
      }

      if (loginUser === 'unknown username') {
        Swal.fire({
          icon: 'error',
          html: this.$t('unknownUsername'),
          animation: false,
          showConfirmButton: true,
        })
      }
      if (loginUser === 'wrong password') {
        Swal.fire({
          icon: 'error',
          html: this.$t('wrongPassword'),
          animation: false,
          showConfirmButton: true,
        })
        this.wrongPw = true
      }
      if (loginUser === 'email is not confirmed') {
        Swal.fire({
          icon: 'error',
          html: this.$t('confirmEmail'),
          animation: false,
          showConfirmButton: true,
        })
        this.showResendMail = true
      }
      if (loginUser === 'user disabled') {
        Swal.fire({
          icon: 'error',
          html: this.$t('userDisabled'),
          animation: false,
          showConfirmButton: true,
        })
      }
      if (loginUser === 'error tfa') {
        Swal.fire({
          icon: 'error',
          html: this.$t('errorTfa'),
          animation: false,
          showConfirmButton: true,
        })
      }
      if (loginUser === 'user locked out') {
        Swal.fire({
          icon: 'warning',
          html: this.$t('userLockedOut'),
          animation: false,
          showConfirmButton: true,
        })
      }
      if (loginUser === 'attemps left') {
        Swal.fire({
          icon: 'warning',
          html: this.$t('userLockedOut'),
          animation: false,
          showConfirmButton: true,
        })
      }
    },
    async resendConfirmMail() {
      var obj = {
        Email: this.user,
        Password: this.password,
      }
      // this.userStore.token = tokenStr
      var base64 = btoa(JSON.stringify(obj))
      const customConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      var response = await httpPost(ApiNames.authorization, base64, customConfig)

      const tokenS = response.data

      var res = await sendEmailConfimationAnonym(tokenS, this.user)

      if (res === 'Email is sended.') {
        Swal.fire({
          icon: 'success',
          html: this.$t('emailSent'),
          showConfirmButton: true,
        })
      } else {
        Swal.fire({
          icon: 'error',
          html: this.$t('errorSendMail'),
          showConfirmButton: true,
        })
      }
    },
    changeType() {
      document.getElementById('user').setAttribute('type', 'email')
    },
  },
  data() {
    return {
      loader: false,
      user: '',
      password: '',
      regExValid: /.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[&"'(\-_)=~#{[|`\\^@\]}^$*¨£µ%,;:!?./§+]).*/,
      MyMdesignLogo: './../img/my_mdesign_white.svg',
      wrongPw: false,
      showResendMail: false,
    }
  },
}
</script>

<style>
.cta-style {
  margin-top: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#registration-button > div {
  padding-left: 0px !important;
}

@media screen and (max-width: 768px) {
  #registration-button > div {
    padding-left: 5px !important;
  }
}

.forgot-password {
  text-align: end;
  text-decoration: underline;
}

.md-theme-default a:not(.md-button) {
  color: #3c4858;
}

.swal2-icon-content {
  font-size: 4rem !important;
}

.swal2-styled.swal2-confirm {
  width: 100px !important;
}

.swal2-container.swal2-center > .swal2-popup {
  bottom: 50px;
}

.swal2-popup.swal2-toast .swal2-html-container {
  padding-top: 30px;
}

@media (max-width: 600px) {
  .swal2-container.swal2-center > .swal2-popup {
    bottom: 120px;
  }
}

.swal2-container a {
  color: #009ee3 !important;
  text-decoration: underline;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.md-card-header {
  padding: 0px !important;
}

.img-title {
  border-radius: 0% !important;
  transform: scale(0.55);
  margin-top: 10px;
}

form button.md-toggle-password {
  margin-top: 1px !important;
  margin-right: 2px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

#login-tile .md-field:before {
  background-color: transparent !important;
}
.remaining-attempts {
  padding-bottom: 15px;
  color: red;
}
.resend-mail-content {
  margin-bottom: 20px;
}
</style>
