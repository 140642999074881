<template>
  <div class="md-layout md-size-33 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
    <loader :loader="loader" />
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-default">
        <h2 class="title text-center">
          {{ $t('resetTitle') }}
        </h2>
      </md-card-header>
      <md-card-content class="sucess-text">
        <h3 class="text-center">
          {{ $t('resetText') }}
        </h3>

        <ValidationObserver v-slot="{ invalid, handleSubmit }">
          <form @submit.prevent="handleSubmit()">
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }" class="text-center">
              <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }, 'password-input']" required>
                <label>{{ $t('email') }}</label>
                <md-input v-model="email" type="email" v-on:keyup.enter="submitResetEmail()"> </md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
            <div class="button-container">
              <md-button
                class="md-primary md-round mt-4"
                slot="footer"
                :disabled="invalid"
                @click.native="submitResetEmail()"
                >{{ $t('submit') }}</md-button
              >
            </div>
          </form>
        </ValidationObserver>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import Swal from 'sweetalert2'
import { useUserStore } from '../../stores/user'
import { sendEmailResetPassword } from '../../libs/emailSender'

extend('required', required)

export default {
  metaInfo() {
    return {
      meta: [{
        name: 'robots', content: this.$route.meta.robots
      }]
    }
  },
  data() {
    return {
      email: '',
      loader: false,
    }
  },
  setup() {
    const userStore = useUserStore()
    return { userStore }
  },
  beforeMount() {
    this.$changePageTitle(this.$t('pageTitleForgotPassword'))
  },
  methods: {
    async submitResetEmail() {
      this.loader = true
      if (this.userStore.userData) {
        this.userStore.userData.Email = this.email
        await sendEmailResetPassword(this.email).then((res) => {
          this.loader = false
          if (res === 'user is not exists.') {
            this.showPopupUserNotExist()
          } else if (res === 'err') {
            this.showPopupError()
          } else {
            this.showPopupSuccess()
          }
        })
      }
      this.$router.push({ path: '/login' })
    },
    showPopupSuccess() {
      Swal.fire({
        position: 'middle-end',
        icon: 'success',
        title: this.$t('forgotPassTitlePopup'),
        html: this.$t('forgotPassTextPopup'),
        showConfirmButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#009ee3',
      })
    },
    showPopupUserNotExist() {
      Swal.fire({
        position: 'middle-end',
        icon: 'error',
        title: this.$t('showPopupTitleNotExist'),
        html: this.$t('showPopupTextNotExist'),
        showConfirmButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#009ee3',
      })
    },
    showPopupError() {
      Swal.fire({
        position: 'middle-end',
        icon: 'error',
        title: this.$t('error'),
        html: this.$t('setNewPassErrorPopup'),
        showConfirmButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#009ee3',
      })
    },
  },
}
</script>

<style scoped>
.password-input {
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  max-width: 400px;
}
</style>
