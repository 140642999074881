var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_vm._v(" | "),_c('template',{slot:"links"},[_c('mobile-menu'),(!_vm.$sidebar.showSidebar && !_vm.roleStudent)?_c('li',{staticClass:"tooltip"},[_c('span',{staticClass:"toolt"},[_c('a',{class:{
              'nav-link product-menu': true,
              'hover-effect': _vm.$sidebar.showProductMenu,
              'product-menu-open': _vm.productURL,
            },on:{"click":_vm.showProductMenuFunction}},[_c('md-icon',{attrs:{"id":_vm.listIconStyle}},[_vm._v("list")]),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.$t('productMenu')))])],1)])]):_vm._e(),(_vm.$sidebar.showSidebar && !_vm.roleStudent)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('productsData'),
          icon: 'list',
          disableCollapse: true,
        }}},[(_vm.roleStudent)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('myproducts'), path: '/products/myproducts' }}}):_vm._e(),(_vm.allow('{1a0be966-f650-45a4-a8b6-2f4aa41f55ca}'))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('companyProducts'),
            path: '/products/companyproducts',
          }}}):_vm._e(),(_vm.allow('{9867a942-1704-4b2c-ad63-b369d0405856}'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('allProducts'), path: '/products/allproducts' }}}):_vm._e()],1):_vm._e(),_c('span',{staticClass:"tooltip"},[(_vm.allow('{367b3416-5022-4a41-bb19-e4c4dd51ddb3}'))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('studentList'),
            icon: 'school',
            path: '/contacts',
          }}}):_vm._e(),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.$t('studentList')))])],1),_c('span',{staticClass:"tooltip"},[(_vm.allow('{4e2393ce-bbd5-4347-a68d-e8869353c7b2}'))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('companyList'),
            icon: 'factory',
            path: '/companies',
          }}}):_vm._e(),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.$t('companyList')))])],1),_c('span',{staticClass:"tooltip"},[(_vm.roleStudent)?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('productsData'),
            icon: 'list',
            path: '/products/myproducts',
          }}}):_vm._e(),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.$t('myproducts')))])],1),_c('span',{staticClass:"tooltip"},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('userPassword'),
            icon: 'lock_outline',
            path: '/password',
          }}}),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.$t('userPassword')))])],1),_c('span',{staticClass:"tooltip"},[(_vm.roleAdmin)?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('roles'),
            icon: 'vpn_key',
            path: '/management/rolestable',
          }}}):_vm._e(),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.$t('roles')))])],1),_c('div',{staticClass:"logout-style tooltip",on:{"click":function($event){return _vm.Logout()}}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('logout'), icon: 'logout', path: '/login' }}}),_c('span',{staticClass:"tooltiptext"},[_vm._v(_vm._s(_vm.$t('logout')))])],1)],1)],2),[_c('transition',{attrs:{"name":"fade"}},[(_vm.$sidebar.showProductMenu)?_c('side-bar-second',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('template',{slot:"links"},[(_vm.roleStudent)?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('myproducts'), path: '/products/myproducts' }}}):_vm._e(),(_vm.allow('{1a0be966-f650-45a4-a8b6-2f4aa41f55ca}'))?_c('sidebar-item',{attrs:{"link":{
              name: _vm.$t('companyProducts'),
              path: '/products/companyproducts',
            }}}):_vm._e(),(_vm.allow('{9867a942-1704-4b2c-ad63-b369d0405856}'))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('allProducts'), path: '/products/allproducts' }}}):_vm._e()],1)],2):_vm._e()],1)],_c('div',{staticClass:"main-panel",attrs:{"main-panel-background":_vm.sidebarBackgroundColor}},[_c('top-navbar',{class:{ 'transition-content': _vm.$sidebar.showProductMenu, 'top-nav-bar-style': true, 'navbar-dashboard': true },attrs:{"data-background-color":_vm.sidebarBackgroundColor}}),_c('div',{class:{
        content: !_vm.$route.meta.hideContent,
        'transition-content': _vm.$sidebar.showProductMenu,
      },attrs:{"data-background-color":_vm.sidebarBackgroundColor},on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer',{class:{ 'transition-content': _vm.$sidebar.showProductMenu, 'footer-position': true },attrs:{"theme-color":_vm.sidebarBackgroundColor}}):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }