import { render, staticRenderFns } from "./EditCompanyContactProfile.vue?vue&type=template&id=cb5895a2&scoped=true"
import script from "./EditCompanyContactProfile.vue?vue&type=script&lang=js"
export * from "./EditCompanyContactProfile.vue?vue&type=script&lang=js"
import style0 from "./EditCompanyContactProfile.vue?vue&type=style&index=0&id=cb5895a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb5895a2",
  null
  
)

export default component.exports