import { render, staticRenderFns } from "./CreateNewContact.vue?vue&type=template&id=84fe7cea&scoped=true"
import script from "./CreateNewContact.vue?vue&type=script&lang=js"
export * from "./CreateNewContact.vue?vue&type=script&lang=js"
import style0 from "./CreateNewContact.vue?vue&type=style&index=0&id=84fe7cea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84fe7cea",
  null
  
)

export default component.exports