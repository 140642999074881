var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('form',{staticClass:"change-pass-card",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('md-card',{staticClass:"change-pass-card"},[_c('md-card-header',{staticClass:"md-card-header-icon md-card-header-primary"},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("https")])],1),_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('changePassword')))])]),_c('md-card-content',[_c('ValidationProvider',{attrs:{"name":"oldpassword","rules":{
            required: true,
            regex: _vm.regExValid,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var passed = ref.passed;
          var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t('oldPassword')))]),_c('md-input',{attrs:{"type":"password"},model:{value:(_vm.oldpassword),callback:function ($$v) {_vm.oldpassword=$$v},expression:"oldpassword"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"password","rules":{
            required: true,
            regex: _vm.regExValid,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var passed = ref.passed;
          var failed = ref.failed;
return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v(_vm._s(_vm.$t('newPassword')))]),_c('md-input',{attrs:{"type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(failed),expression:"failed"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(passed),expression:"passed"}],staticClass:"success"},[_vm._v("done")])],1)],1)]}}])}),_c('div',{class:[{ 'show-validation': _vm.oldAndNewPasswordAreTheSame }, { 'password-validation': true }]},[_vm._v(" "+_vm._s(_vm.$t('newAndOldPasswordAreSame'))+" ")]),_c('div',[_vm._v(_vm._s(_vm.$t('setPasswordRule')))])],1),_c('md-card-actions',[_c('md-button',{staticClass:"md-primary update-password md-round",attrs:{"type":"submit","disabled":_vm.isFormValid}},[_vm._v(" "+_vm._s(_vm.$t('updatePassword'))+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }