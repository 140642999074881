import { render, staticRenderFns } from "./TopNavbar.vue?vue&type=template&id=63b27979&scoped=true"
import script from "./TopNavbar.vue?vue&type=script&lang=js"
export * from "./TopNavbar.vue?vue&type=script&lang=js"
import style0 from "./TopNavbar.vue?vue&type=style&index=0&id=63b27979&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b27979",
  null
  
)

export default component.exports