<template>
  <div class="md-layout md-size-33 md-medium-size-100 md-small-size-100 md-xsmall-size-100">
    <loader :loader="loader" />
    <md-card v-if="!loader">
      <md-card-header class="md-card-header-icon md-card-header-default">
        <h2 class="title text-center">
          {{ $t('confirmRegistrationTitle') }}
        </h2>
      </md-card-header>
      <md-card-content class="sucess-text">
        {{ $t('successfulRegistration') }} <a href="/login">{{ $t('link') }}</a>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import Loader from '@/components/Loader/Loader.vue'
import { useUserStore } from '../../stores/user'
import { useCompanyStore } from '../../stores/company'
import { i18n } from '@/main';

export default {
  data() {
    return {
      productID: '{691a838c-0e9f-11ee-b3bc-0050561ebe44}',
      constentTypes: [
        {
          ID: '{31099e91-f925-4a81-a062-712f9c9d6bc9}',
          Name: 'Newsletter',
          Description: 'mit Newsletter kontaktieren',
        },
        {
          ID: '{daade10e-2f9d-4a01-91c3-6a6987e37588}',
          Name: 'E-Mail',
          Description: 'mit E-Mail kontaktieren',
        },
      ],
      userID: null,
      token: null,
      Email: null,
      userCreated: false,
      LastName: '',
      FirstName: '',
      Country: '',
      City: '',
      Street: '',
      ZipCode: '',
      HouseNumber: '',
      CompanyID: '',
      Salutation: '',
      Title: '',
      contactID: '',
      roloffMatekProductID: '{691a838c-0e9f-11ee-b3bc-0050561ebe44}',
      loader: false,
    }
  },
  methods: {
    getTokenFromURL() {
      const urlParams = new URLSearchParams(window.location.search)
      this.token = urlParams.get('id')
      this.userStore.token = this.token
    },
    getUserDataFromToken() {
      const tokenDecode = JSON.parse(decodeURIComponent(escape(atob(this.token.split('.')[1]))))
      this.Country = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country']
      this.City = tokenDecode['city']
      this.Street = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/streetaddress']
      this.ZipCode = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/postalcode']
      this.HouseNumber = tokenDecode['housenumber']
      this.CompanyID = tokenDecode['customernumber']
      this.Salutation = tokenDecode['salutation']
      this.Title = tokenDecode['companyname']

      const emailFromToken = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']
      this.Email = emailFromToken
      this.userStore.userData.Email = emailFromToken
      const firstnameFromToken = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']
      this.FirstName = firstnameFromToken
      const lastnameFromToken = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname']
      this.LastName = lastnameFromToken
    },
    async getUserID() {
      await this.userStore.createTokenByEmail(this.userStore.userData.Email).then((result) => {
        if (result) {
          const tokenDecode = JSON.parse(atob(result.split('.')[1]))
          this.userID = tokenDecode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
        }
      })
    },
    async createUser() {
      const dataObj = {
        Email: this.Email,
        LastName: this.LastName,
        FirstName: this.FirstName,
        CompanyID: this.CompanyID,
        Salutation: this.Salutation,
        Language: this.$i18n.locale.split('-')[0],
        Title: this.Title,
      }
      await this.userStore.createUser(dataObj, this.token).then((userCreated) => {
        this.userStore.userData.ID = userCreated
        if (userCreated) {
          this.userCreated = true
          return true
        } else {
          this.userCreated = false
          return false
        }
      })
    },
    async confirmEmail() {
      await this.userStore.confirmEmail(this.token).then((result) => {
        return result
      })
    },
    async updateUser() {
      const contact = await this.userStore.getContactIDbyEmail(this.Email, this.token)
      this.contactID = contact.data[0].ID

      const getUserData = await this.userStore.getUserDataByEmail(this.Email, this.token)
      const userID = getUserData.data[0].ID
      const MDUserRoleID = getUserData.data[0].MDUserRoleID
      const Username = getUserData.data[0].Username
      const PasswordHash = getUserData.data[0].PasswordHash
      const Disable = getUserData.data[0].Disable
      const Verified = getUserData.data[0].Verified
      const updateObject = {
        ContactID: this.contactID,
        ID: userID,
        MDUserRoleID: MDUserRoleID,
        Username: Username,
        PasswordHash: PasswordHash,
        Disable: Disable,
        Verified: Verified,
      }
      await this.userStore.updateUserWithContactID(updateObject, this.token)
    },
    async confirmEmailAndCreateUser() {
      this.getUserDataFromToken()
      await this.createUser()
      await this.updateUser()
      await this.confirmEmail()
      await this.createConsentType()
    },
    async createAddress() {
      const dataObj = {
        Country: this.Country,
        City: this.City,
        Street: this.Street,
        ZipCode: this.ZipCode,
        HouseNumber: this.HouseNumber,
      }
      const addressID = await this.userStore.createAddress(dataObj, this.token)
      const contactHasAdressID = this.userStore.createLinkContractHasAddress(addressID, this.contactID, this.token)
    },
    async createConsentType() {
      const now = this.companyStore.getNow()

      await this.constentTypes.forEach(async (type) => {
        var obj = {
          ContactID: this.userStore.userData.ID,
          ConsentTypeID: type.ID,
          Consent: true,
          ConsentDate: now,
        }
        var res = await this.userStore.createContactHasConsentType(obj, this.token)
      })
    },
  },
  beforeMount() {
    this.$changePageTitle(this.$t('pageTitleConfirmRegistration'))
  },
  async mounted() {
    this.loader = true

    this.getTokenFromURL()
    await this.confirmEmailAndCreateUser()
    await this.createAddress()
    if (window.location.href.includes('isRoloffMatek') && this.userCreated) {
      const res = await this.userStore.createProductToContact(this.userStore.userData.ID, this.roloffMatekProductID)
    }
    this.loader = false
    setTimeout(function () {
      window.location.href = '/login'
    }, 2500)
  },

  setup() {
    useCompanyStore
    const userStore = useUserStore()
    const companyStore = useCompanyStore()
    return { userStore, companyStore }
  },
}
</script>

<style scoped>
.sucess-text {
  text-align: center;
  font-size: 24px;
}

.sucess-text a {
  color: #009ee3;
}
</style>
