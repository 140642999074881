<template>
  <form class="edit-profile">
    <loader :loader="loader" />
    <md-card class="card-content">
      <md-card-header class="md-card-header-icon header-style" :class="getClass(headerColor)">
        <div>
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
          <h4 class="title user-profile-title">
            {{ $t('profileTitle') }}
          </h4>
          <h3>{{ $t('profileText') }}</h3>
        </div>
        <div class="md-layout avatar">
          <div class="picture-container">
            <div class="picture">
              <div v-if="!image">
                <img :src="avatar" class="picture-src" title="" />
              </div>
              <div v-else>
                <img class="img-style" :src="image" />
              </div>
            </div>
            <h6 class="description" @click="$vm2.open('modal-1')">
              {{ $t('choosePicture') }}
            </h6>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-50" v-if="!isStudent">
            <md-field>
              <label>{{ $t('customerNumber') }}</label>
              <md-input disabled v-model="companyNumber" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
              <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                <label>{{ $t('email') }}</label>
                <md-input v-model="email" type="email" disabled="disabled"> </md-input>

                <slide-y-down-transition>
                  <md-icon class="error" v-show="failed">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="passed">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </ValidationProvider>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label for="salutation">{{ $t('salutation') }}</label>
              <md-select v-model="salutation" name="salutation" id="salutation">
                <md-option value="Herr">{{ $t('mr') }}</md-option>
                <md-option value="Frau">{{ $t('ms') }}</md-option>
                <md-option value="NoSalutation">{{ $t('noSalutaition') }}</md-option>
              </md-select>
            </md-field>
          </div>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>{{ $t('firstName') }}</label>
              <md-input v-model="firstname" type="text" id="firstname"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>{{ $t('lastName') }}</label>
              <md-input v-model="lastname" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>{{ $t('street') }}</label>
              <md-input v-model="street" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>{{ $t('houseNumber') }}</label>
              <md-input v-model="housenumber" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field>
              <label>{{ $t('city') }}</label>
              <md-input v-model="city" type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <select-country :value="country"></select-country>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-50">
            <div>
              <ValidationProvider name="zipcode" rules="required|numeric" v-slot="{ passed, failed }">
                <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                  <label> {{ $t('zipCode') }} </label>
                  <md-input v-model="zipcode" type="text"> </md-input>

                  <slide-y-down-transition>
                    <md-icon class="error" v-show="failed">close</md-icon>
                  </slide-y-down-transition>
                  <slide-y-down-transition>
                    <md-icon class="success" v-show="passed">done</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </ValidationProvider>
            </div>
          </div>

          <div class="md-layout-item md-small-size-100 md-size-50">
            <md-field class="switch-field">
              <md-switch @change="toggleTwoFA" class="field-switch" v-model="md_user.TwoFactorEnabled">{{
                $t('twoFAuthentication')
              }}</md-switch>
            </md-field>
          </div>
          <div v-if="!isStudent" class="md-layout-item md-small-size-100 md-size-50"></div>
          <div class="update-profile-button md-layout-item md-size-100 text-right">
            <div class="">
              <md-button
                class="md-raised md-primary mt-4 md-round"
                @click="updateProfile()"
                :disabled="!allow('{81a28fc4-2cb1-4176-883f-4bee441fd5bb}')"
                >{{ $t('save') }}
              </md-button>
            </div>
            <div class="text-center"></div>

            <form enctype="multipart/form-data"></form>
            <div></div>
            <modal-vue name="modal-1" noFooter @on-close="$vm2.close('modal-1')" noHeader>
              <div>
                <div class="avatar-modal-title">{{ $t('avatarModal') }}</div>
                <div class="upload-instruction">{{ $t('uploadOrSelect') }}</div>
                <vue-avatar
                  :width="200"
                  :height="200"
                  :scale="+sliders.simple"
                  ref="vueavatar"
                  :border="0"
                  class="upload-avatar"
                  :placeholderSvg="defaultSvg"
                >
                </vue-avatar>
                <slider v-model="sliders.simple" :range="{ min: 1, max: 10 }"> </slider>
                <div class="avatar-buttons">
                  <md-button v-on:click="$vm2.close('modal-1')" class="md-round">{{ $t('cancel') }}</md-button>
                  <md-button
                    class="md-primary md-round"
                    v-on:click="
                      saveNewAvatar()
                      $vm2.close('modal-1')
                    "
                    >{{ $t('save') }}
                  </md-button>
                </div>
              </div>
            </modal-vue>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import { extend } from 'vee-validate'
import { VueAvatar } from 'vue-avatar-editor-improved'
import { required, min_value, email, numeric } from 'vee-validate/dist/rules'
import SelectCountry from '../../components/SelectCountry/SelectCountry.vue'
import { useUserStore } from '../../stores/user'
import Swal from 'sweetalert2'
import { Slider } from '@/components'
import { useCompanyStore } from '../../stores/company'
import { permissionMixin } from '../RoleManagement/permissionMixin'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)

export default {
  mixins: [permissionMixin],
  metaInfo() {
    return {
      meta: [{
        robots: this.$route.meta.robots
      }]
    }
  },
  components: { SelectCountry, VueAvatar, Slider },
  name: 'edit-profile-form',
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
    avatar: {
      type: String,
      default: './img/default-avatar.png',
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    return { companyStore, userStore }
  },
  data() {
    return {
      customernumber: null,
      salutation: null,
      email: null,
      lastname: null,
      firstname: null,
      street: null,
      housenumber: null,
      city: null,
      zipcode: null,
      country: null,
      loader: false,
      scale: 1,
      image: null,
      companyNumber: null,
      sliders: {
        simple: 5,
      },
      twoFAuth: false,
      newAvatar: null,
      isStudent: false,
      md_user: {
        TwoFactorEnabled: false,
      },
      defaultSvg:
        '<svg xmlns="http://www.w3.org/2000/svg" height="75px" viewBox="0 0 24 24" width="75px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/></svg>',
    }
  },
  async beforeMount() {
    this.$changePageTitle(this.$t('pageTitleEditPersonalData'))
    this.company = this.companyStore.company
    this.country = this.userStore.userAddress.Country
    if (this.userStore.userData.Image) {
      await this.getAvatarImg()
    } else {
      this.image = this.avatar
    }
    if (this.userStore.valid) {
      this.customernumber = this.userStore.userData.ID
      this.salutation = this.userStore.userData.Salutation
      this.email = this.userStore.userData.Email
      this.lastname = this.userStore.userData.LastName
      this.firstname = this.userStore.userData.FirstName
      this.street = this.userStore.userAddress.Street
      this.housenumber = this.userStore.userAddress.Housenumber
      this.city = this.userStore.userAddress.City
      this.zipcode = this.userStore.userAddress.ZipCode
      this.country = this.userStore.userAddress.Country
      this.companyID = this.userStore.userData.CompanyID
    } else {
      this.$router.push({ name: 'Login' })
    }
    if (this.companyID && this.companyID !== 'NULL') {
      const response = await this.companyStore.getCompanyDataByID(this.companyID, this.userStore.token)
      if (response.status === 200) this.companyNumber = response.data[0].CustomerNr
    }

    var resMd_user = await this.userStore.getUserDataByEmail(this.userStore.userData.Email, this.userStore.token)
    if (resMd_user.status === 200) {
      this.md_user = resMd_user.data[0]
      this.twoFAuth = resMd_user.data[0].TwoFactorEnabled
    }

    this.isStudent = this.userStore.role === 'Student'
  },

  methods: {
    async getAvatarImg() {
      const reposnse = await this.userStore.getAvatar()
      const reader = new FileReader()
      reader.readAsDataURL(reposnse.data)
      reader.onload = () => {
        this.image = reader.result
        this.userStore.userData.AvatarImage = reader.result
      }
      this.$eventBus.$emit('updateAvatar')
    },
    async uploadAvatar(file) {
      this.loader = true
      const responce = await this.userStore.uploadAvatar(file)
      this.loader = false
      if (responce) {
        this.userStore.userData.Image = 1
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('avatarUpdated'),
          showConfirmButton: false,
          timer: 1500,
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('profileNotUpdated'),
        })
      }
    },
    async saveNewAvatar() {
      const fileName = document.querySelectorAll('input[accept="image/*"]')[0].files[0].name
      const defaultSvg =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAAAXNSR0IArs4c6QAABSJJREFUeF7t1bERwDAMxLB4/6UzgV2wfaRXIci8nM9HgMBV4LAhQOAuIBCvg8BDQCCeBwGBeAMEmoA/SHMzNSIgkJFDW7MJCKS5mRoREMjIoa3ZBATS3EyNCAhk5NDWbAICaW6mRgQEMnJoazYBgTQ3UyMCAhk5tDWbgECam6kRAYGMHNqaTUAgzc3UiIBARg5tzSYgkOZmakRAICOHtmYTEEhzMzUiIJCRQ1uzCQikuZkaERDIyKGt2QQE0txMjQgIZOTQ1mwCAmlupkYEBDJyaGs2AYE0N1MjAgIZObQ1m4BAmpupEQGBjBzamk1AIM3N1IiAQEYObc0mIJDmZmpEQCAjh7ZmExBIczM1IiCQkUNbswkIpLmZGhEQyMihrdkEBNLcTI0ICGTk0NZsAgJpbqZGBAQycmhrNgGBNDdTIwICGTm0NZuAQJqbqREBgYwc2ppNQCDNzdSIgEBGDm3NJiCQ5mZqREAgI4e2ZhMQSHMzNSIgkJFDW7MJCKS5mRoREMjIoa3ZBATS3EyNCAhk5NDWbAICaW6mRgQEMnJoazYBgTQ3UyMCAhk5tDWbgECam6kRAYGMHNqaTUAgzc3UiIBARg5tzSYgkOZmakRAICOHtmYTEEhzMzUiIJCRQ1uzCQikuZkaERDIyKGt2QQE0txMjQgIZOTQ1mwCAmlupkYEBDJyaGs2AYE0N1MjAgIZObQ1m4BAmpupEQGBjBzamk1AIM3N1IiAQEYObc0mIJDmZmpEQCAjh7ZmExBIczM1IiCQkUNbswkIpLmZGhEQyMihrdkEBNLcTI0ICGTk0NZsAgJpbqZGBAQycmhrNgGBNDdTIwICGTm0NZuAQJqbqREBgYwc2ppNQCDNzdSIgEBGDm3NJiCQ5mZqREAgI4e2ZhMQSHMzNSIgkJFDW7MJCKS5mRoREMjIoa3ZBATS3EyNCAhk5NDWbAICaW6mRgQEMnJoazYBgTQ3UyMCAhk5tDWbgECam6kRAYGMHNqaTUAgzc3UiIBARg5tzSYgkOZmakRAICOHtmYTEEhzMzUiIJCRQ1uzCQikuZkaERDIyKGt2QQE0txMjQgIZOTQ1mwCAmlupkYEBDJyaGs2AYE0N1MjAgIZObQ1m4BAmpupEQGBjBzamk1AIM3N1IiAQEYObc0mIJDmZmpEQCAjh7ZmExBIczM1IiCQkUNbswkIpLmZGhEQyMihrdkEBNLcTI0ICGTk0NZsAgJpbqZGBAQycmhrNgGBNDdTIwICGTm0NZuAQJqbqREBgYwc2ppNQCDNzdSIgEBGDm3NJiCQ5mZqREAgI4e2ZhMQSHMzNSIgkJFDW7MJCKS5mRoREMjIoa3ZBATS3EyNCAhk5NDWbAICaW6mRgQEMnJoazYBgTQ3UyMCAhk5tDWbgECam6kRAYGMHNqaTUAgzc3UiIBARg5tzSYgkOZmakRAICOHtmYTEEhzMzUiIJCRQ1uzCQikuZkaERDIyKGt2QQE0txMjQgIZOTQ1mwCAmlupkYEBDJyaGs2AYE0N1MjAgIZObQ1m4BAmpupEQGBjBzamk1AIM3N1IiAQEYObc0mIJDmZmpEQCAjh7ZmExBIczM1IiCQkUNbswkIpLmZGhEQyMihrdkEBNLcTI0ICGTk0NZsAgJpbqZGBAQycmhrNgGBNDdTIwICGTm0NZuAQJqbqREBgYwc2ppNQCDNzdSIgEBGDm3NJiCQ5mZqREAgI4e2ZhMQSHMzNSIgkJFDW7MJCKS5mRoR+AErVADJkrjltgAAAABJRU5ErkJggg=='
      this.newAvatar = this.$refs.vueavatar.getImageScaled()

      const url = this.newAvatar.toDataURL()

      if (url !== defaultSvg) {
        await fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            this.file = new File([blob], fileName, { type: 'image/png' })
          })
        await this.uploadAvatar(this.file)
        await this.getAvatarImg()
      }
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    async updateProfile() {
      this.userStore.userData.Salutation = this.salutation
      this.userStore.userData.LastName = this.lastname
      this.userStore.userData.FirstName = this.firstname

      let updateUserDataObject = {
        Salutation: this.userStore.userData.Salutation,
        FirstName: this.userStore.userData.FirstName,
        LastName: this.userStore.userData.LastName,
        ID: this.userStore.userData.ID,
        CompanyID: this.userStore.userData.CompanyID,
        Title: this.userStore.userData.Title,
        Gender: this.userStore.userData.Gender,
        Phone: this.userStore.userData.Phone,
        Email: this.userStore.userData.Email,
        Position: this.userStore.userData.Position,
        MainContact: this.userStore.userData.MainContact,
        Language: this.userStore.userData.Language,
        CreatedBy: this.userStore.userData.CreatedBy,
        Created: this.userStore.userData.Created,
        ModifiedBy: this.userStore.userData.ModifiedBy,
        Modified: this.userStore.userData.Modified,
        Image: Number(this.userStore.userData.Image),
      }

      this.userStore.userAddress.Street = this.street
      this.userStore.userAddress.Housenumber = this.housenumber
      this.userStore.userAddress.ZipCode = this.zipcode
      this.userStore.userAddress.City = this.city
      let updateUserAddressObject = {
        Street: this.userStore.userAddress.Street,
        Housenumber: this.userStore.userAddress.Housenumber,
        City: this.userStore.userAddress.City,
        ZipCode: this.userStore.userAddress.ZipCode,
        Country: this.userStore.userAddress.Country,
      }

      this.loader = true
      const statusUpdate = await this.userStore.updateUserData(updateUserDataObject, updateUserAddressObject)

      this.loader = false
      if (statusUpdate === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('profileUpdated'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: this.$t('profileNotUpdated'),
          icon: 'error',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      }
    },
    async toggleTwoFA() {
      var text, successText, errorText, title
      var enable
      if (this.md_user.TwoFactorEnabled) {
        enable = true
        title = this.$t('activate')
        text = this.$t('activate2FAText')
        successText = this.$t('successActivate2FA')
        errorText = this.$t('errorActivate2FA')
      } else {
        enable = false
        title = this.$t('deactivate')
        text = this.$t('deactivate2FAText')
        successText = this.$t('successDeactivate2FA')
        errorText = this.$t('errorDeactivate2FA')
      }

      var result = await Swal.fire({
        icon: 'question',
        title: title,
        html: text,
        showCancelButton: true,
      }).then((result) => {
        return result
      })

      if (result.isConfirmed) {
        if (enable) {
          var res = await this.userStore.enableTFA()
        } else {
          var res = await this.userStore.disableTFA()
        }

        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.$t('success'),
            html: successText,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
            html: errorText,
          })
          this.md_user.TwoFactorEnabled = !this.md_user.TwoFactorEnabled
        }
      } else {
        this.md_user.TwoFactorEnabled = !this.md_user.TwoFactorEnabled
      }
    },
  },
}
</script>
<style>
/deep/ .md-card {
  width: 96%;
  margin: 0 auto;
}

.picture {
  width: 106px;
  height: 106px;
  background-color: #999999;
  border: 4px solid #cccccc;
  color: #ffffff;
  border-radius: 50%;
  margin: 15px 20px;
  overflow: hidden;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

.picture-src {
  width: 100%;
}

.picture-container {
  text-align: center;
}

.choose-file {
  margin: 15px 20px;
  width: 110px;
  height: 140px;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
}

.header-style {
  display: flex;
  justify-content: space-between;
}

.avatar {
  max-width: 190px;
  justify-content: end;
}

.description {
  margin-top: 5px;
  margin-bottom: 0px;
  cursor: pointer;
}

.img-style {
  height: 100px !important;
  object-fit: cover !important;
}

.upload-avatar {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}

.upload-avatar canvas {
  border-radius: 50%;
  z-index: 2;
  border: dotted 2px #ccc;
  position: relative;
  top: -40px;
}

.slider {
  max-width: 150px;
  display: flex;
  margin: 0 auto;
}

#vm2__cover_wrapper_modal-1 {
  z-index: 3;
}

.avatar-buttons {
  margin: 10px 0px;
  display: flex;
  justify-content: space-around;
}

.avatar-modal-title {
  margin: 10px;
  font-size: 1.5rem;
  text-align: center;
}

.upload-instruction {
  position: relative;
  top: 155px;
  max-width: 160px;
  text-align: center;
  margin: 0 auto;
}

.update-profile-button {
  display: flex;
  flex: 1;
}

form.edit-profile {
  margin: 0 auto;
  width: 66.6%;

  @media screen and (max-width: 991px) {
    width: auto;
  }
}
</style>
